import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export const TermsOfUsePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.sessionStorage.removeItem("rd-email");
    const getIsSiteEnabled =
      window.sessionStorage.getItem("isReadySiteEnabled");
    const readyCurrentTime = window.sessionStorage.getItem("readyCurrentTime");
    const readyTimeDiff1 = dayjs();
    const readyTimeDiff2 = dayjs(readyCurrentTime);
    const minutes = readyTimeDiff1.diff(readyTimeDiff2, "minutes");
    if (
      !window.location.hostname.includes("dev.") &&
      !window.location.hostname.includes("localhost")
    ) {
      return;
    } else {
      if (getIsSiteEnabled === "true" && minutes < 30) {
        return;
      } else {
        window.sessionStorage.removeItem("readyCurrentTime");
        window.sessionStorage.removeItem("isReadySiteEnabled");
        window.location = "https://dev.readyplatform.co/";
      }
    }
  }, []);

  return (
    <main className="relative h-full w-full flex flex-col items-center justify-start text-left bg-vanilla">
      <section>
        <img
          className="mt-16 sm:mt-0"
          width={100}
          src={require("./../assets/images/ready-logo-charcoal.svg").default}
          alt="Ready Platform Logo"
        />
        <p className="text-3xl w-full mt-12 my-6 font-bold font-ff">
          Ready Platform Inc. Terms of Service
        </p>
        <p className="mb-4">
          <span className="">Effective: </span>
          <span className="">April 12, 2024</span>
        </p>
        <p className="mb-4">
          <span className="">
            These Terms of Service constitute a legally binding agreement
            between you (&ldquo;
          </span>
          <span className="">you</span>
          <span className="">&rdquo; or &ldquo;</span>
          <span className="">User</span>
          <span className="">
            &rdquo;) and Ready Platform, Inc. (together with its affiliates,
            &ldquo;
          </span>
          <span className="">Company</span>
          <span className="">&rdquo;, &ldquo;</span>
          <span className="">we</span>
          <span className="">,&rdquo; &ldquo;</span>
          <span className="">our</span>
          <span className="">&rdquo; or &ldquo;</span>
          <span className="">us</span>
          <span className="">
            &rdquo;) governing your use of our products, mobile application,
            services, and the &ldquo;readyplatform.co&rdquo; website (the
            &ldquo;
          </span>
          <span className="">Site</span>
          <span className="">&rdquo;, &ldquo;</span>
          <span className="">Ready Platform</span>
          <span className="">
            &rdquo; and collectively with the foregoing, the &ldquo;
          </span>
          <span className="">Services</span>
          <span className="">&rdquo;). </span>
        </p>
        <p className="mb-4">
          <span className="">
            YOU ACKNOWLEDGE AND AGREE THAT, BY CLICKING ON THE &ldquo;I
            AGREE&rdquo; OR SIMILAR BUTTON, REGISTERING FOR AN ACCOUNT, OR
            ACCESSING OR USING THE SERVICES, YOU ARE INDICATING THAT YOU HAVE
            READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE,
            WHETHER OR NOT YOU HAVE REGISTERED WITH THE SITE, YOU ALSO
            ACKNOWLEDGE THAT YOU ARE AT LEAST 18 YEARS OF AGE. IF YOU DO NOT
            AGREE TO THESE TERMS OF SERVICE, THEN YOU HAVE NO RIGHT TO ACCESS OR
            USE THE SERVICES. &nbsp;These Terms of Service are effective as of
            the date you first click &ldquo;I agree&rdquo; (or similar button or
            checkbox) or use or access the Services, whichever is earlier.
            &nbsp;
          </span>
        </p>
        <p className="mb-4">
          <span className="">
            Any personal data you submit to us or which we collect about you is
            governed by our Privacy Policy (&ldquo;
          </span>
          <span className="">Privacy Policy</span>
          <span className="">&rdquo;), available at </span>
          <span className="">
            https://www.readyplatform.co/prototype/privacy-policy
          </span>
          <span className="">
            . &nbsp;You acknowledge that by using the Services, you have
            reviewed the Privacy Policy. &nbsp;The Privacy Policy is
            incorporated by reference into these Terms of Service and together
            form and are hereinafter referred to as this &ldquo;
          </span>
          <span className="">Agreement.</span>
          <span className="">&rdquo;</span>
        </p>
        <ul className="mb-4 start">
          <li className="c2 li-bullet-0">
            <span className="font-bold">1. Use of the Services. &nbsp;</span>
            <span className="">
              Company grants a non-exclusive, worldwide, fully paid-up license
              during the Term to use the Services. Services are intended to
              allow you to connect to other users looking to get in a serious
              romantic relationship. We want our users to be able express
              themselves as much as possible on Ready Platform, but we have to
              impose restrictions on certain content which:
            </span>
          </li>
        </ul>
        <ul className="mb-4 ml-12 lst-kix_list_12-1 start">
          <li className="c1 li-bullet-1">
            <span className="">
              a. is illegal or encourages, promotes or incites any illegal
              activity;
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="">b. is harmful to minors;</span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="">c. is defamatory or libelous;</span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="">
              d. itself, or the posting of which, infringes any third
              party&rsquo;s rights (including, without limitation, intellectual
              property rights and privacy rights);
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="">
              e. shows another person which was created or distributed without
              that person&rsquo;s consent;
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="">
              f. contains language or imagery which could be deemed offensive or
              is likely to harass, upset, embarrass, alarm or annoy any other
              person;
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="">
              g. is obscene, pornographic, violent or otherwise may offend human
              dignity;
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="">
              h. is abusive, insulting or threatening, discriminatory or which
              promotes or encourages racism, sexism, hatred or bigotry;
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="">
              i. relates to commercial activities (including, without
              limitation, sales, competitions and advertising, links to other
              websites or premium line telephone numbers);
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="">
              j. involves the transmission of &ldquo;junk&rdquo; mail or
              &ldquo;spam&rdquo;;
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="">
              k. impersonates or intends to deceive or manipulate a person
              (including, without limitation, scams and inauthentic behavior);
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="">
              l. contains any spyware, adware, viruses, corrupt files, worm
              programs or other malicious code designed to interrupt, damage or
              limit the functionality of or disrupt any Services, hardware,
              telecommunications, networks, servers or other equipment, Trojan
              horse or any other material designed to damage, interfere with,
              wrongly intercept or expropriate any data or personal information
              whether from Ready Platform or otherwise.
            </span>
          </li>
        </ul>
        <ol className="mb-4">
          <li className="c2 li-bullet-0 mb-4">
            <span className="font-bold">2. Ownership</span>
            <span className="">. </span>
            <span className="">&nbsp;</span>
            <span className="">
              The Services and all related intellectual property rights (whether
              or not registered) shall remain the exclusive property of Company.
              &nbsp;Under no circumstances shall User (i) share login
              information for the Services (or it&rsquo;s hosting location) with
              any third party, (ii) modify or reverse engineer the Services or
              (iii) sell, license, distribute, or otherwise transfer to a third
              party or encumber the Services without Company&rsquo;s prior
              written consent. For purposes of clarity, any derivative works
              created solely by User using the Services, but not including any
              portions of the Services (the &ldquo;
            </span>
            <span className="c7">User Derivative Works</span>
            <span className="">
              &rdquo;) shall be the property of User, but User shall only have a
              license to the Services necessary to operate the User Derivative
              Works for internal evaluation purposes during the Term. User
              hereby grants Company a perpetual, irrevocable, worldwide license
              to use any Feedback (as defined below) User communicates to
              Company during the Term, without compensation, without any
              obligation to report on such use, and without any other
              restriction. Company&rsquo;s rights granted in the previous
              sentence include, without limitation, the right to exploit
              Feedback in any and every way, as well as the right to grant
              sublicenses under copyright, patent, and any other form of
              intellectual property. Feedback will not be considered
              User&rsquo;s confidential information or its trade secret. &ldquo;
            </span>
            <span className="c7">Feedback</span>
            <span className="">
              &rdquo; refers to any suggestion or idea for modifying any of
              Company&rsquo;s products or services.{" "}
            </span>
          </li>
          <li className="c2 li-bullet-0 mb-4">
            <span className="font-bold">3. License to Use Customer Data</span>
            <span className="">
              . User, on behalf of itself and its suppliers and licensors (as
              applicable) hereby grants Company during the Term a limited,
              non-exclusive, non-transferable (other than pursuant to Section 9
              of this Agreement), non-sublicensable (other than to
              subcontractors of the Company) license to use, view, copy,
              reformat, distribute, display and analyze the User data solely for
              purposes of developing and improving the Platform.
            </span>
          </li>
          <li className="c2 li-bullet-0 mb-4">
            <span className="font-bold">4. Customer Marks; Marketing</span>
            <span className="">
              . User hereby grants to Company a worldwide, non-exclusive,
              non-transferable license to use and display all Marks provided by
              User to Company for inclusion in the Platform solely for the
              purpose of Company&#39;s provision of the Platform, as selected by
              User from time to time. Company is permitted to disclose that User
              is one of its customers to any third-party at its sole discretion,
              and, to place User&#39;s name and logo on its website and
              marketing materials for this purpose, subject to compliance with
              any logo or branding guidelines provided by User.
            </span>
          </li>
          <li className="c2 li-bullet-0 mb-4">
            <span className="font-bold">5. Term and Termination. &nbsp;</span>
            <span className="">
              This Agreement shall commence on the Effective Date and shall
              terminate in six (6) months of the Effective Date, provided that
              (i) unless Company gives User thirty (30) days advance written
              notice of termination, this Agreement shall automatically renew
              for additional one (1)-month terms, and (ii) Company may, at its
              option, terminate this Agreement immediately if User fails to
              comply with any terms and conditions of this Agreement.
              &nbsp;Sections 3-7 shall survive termination or expiration of this
              Agreement. &nbsp;
            </span>
          </li>
          <li className="c2 li-bullet-0 mb-4">
            <span className="font-bold">6. No Warranty. &nbsp;</span>
            <span className="">
              THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo;, AND COMPANY MAKES
              NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT THERETO, INCLUDING
              BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR
              FITNESS FOR A PARTICULAR PURPOSE. &nbsp;
            </span>
          </li>
          <li className="c2 li-bullet-0 mb-4">
            <span className="font-bold">
              7. Limitation of Liability. &nbsp;
            </span>
            <span className="">
              UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE,
              SYSTEM FAILURE OR NETWORK OUTAGE, WILL COMPANY OR ITS AFFILIATES
              BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
              PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES THAT RESULT FROM THIS
              AGREEMENT, EVEN IF COMPANY OR ITS AUTHORIZED REPRESENTATIVE HAS
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL
              COMPANY&rsquo;S TOTAL LIABILITY UNDER THIS AGREEMENT, WHETHER IN
              CONTRACT, TORT (INCLUDING NEGLIGENCE) STRICT LIABILITY OR OTHER
              LEGAL THEORY, EXCEED ONE HUNDRED DOLLARS ($100).
            </span>
          </li>
          <li className="c2 li-bullet-0 mb-4">
            <span className="font-bold">8. Confidential Information. </span>
            <span className="">
              User will not disclose to any third party the results of the
              evaluation of the Services or other performance or functional
              evaluation of the Services without prior written approval of
              Company. &nbsp;Company shall have the right to use for any purpose
              any information regarding the Services gained as a result of
              User&rsquo;s use and evaluation of the Services. &nbsp;Such
              information shall include but not be limited to changes,
              modifications, corrections and improvements to the Services
              suggested by User, but specifically excludes the User Derivative
              Works.
            </span>
          </li>
          <li className="c2 li-bullet-0 mb-4">
            <span className="font-bold">
              9. Miscellaneous and Entire Agreement.
            </span>
            <span className="">
              &nbsp; The parties are independent contractors, and nothing in
              this Agreement is intended to or shall create any agency,
              partnership or joint venture relationship between them. &nbsp;This
              Agreement shall be governed by the laws of the State of
              California. &nbsp;Neither party may assign this Agreement or any
              of its rights or obligations hereunder without the other
              party&rsquo;s prior written consent, except in connection with any
              merger, consolidation, reorganization, sale or similar transaction
              of the assigning party in which the surviving entity is not a
              direct competitor of the non-assigning party. &nbsp;The waiver of
              any breach or default of this Agreement will not constitute a
              waiver of any subsequent breach or default, and will not act to
              amend or negate the rights of the waiving party. &nbsp;This
              Agreement constitutes the entire Agreement between Company and
              User with respect to the subject matter hereof and not be modified
              without the prior written consent of both parties.
            </span>
          </li>
        </ol>
      </section>
      <section className="flex flex-col items-center justify-start w-full z-10 md:pl-12 lg:pl-[100px] text-charcoal mt-10">
        <div className="w-full flex items-center justify-start">
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/terms-of-use")}
          >
            Terms of Service
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/careers")}
          >
            Careers
          </p>
        </div>
        <p className="mr-1 w-full text-left pt-2">
          Questions or concerns? Reach out to us at{" "}
          <a href="mailto:waitlist@readyplatform.co">
            waitlist@readyplatform.co
          </a>
        </p>
      </section>
    </main>
  );
};
