export const getWaitlistUser = /* GraphQL */ `
  query GetWaitlistUser($id: ID!) {
    getWaitlistUser(id: $id) {
      id
      email
      relationshipStatus
      cityState
      referralCode
      ageRange
      gender
      genderPreference
      readyInterests
      howDidYouHearAboutUs
      helpReady
      features
      comments
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const searchWaitlistUsers = /* GraphQL */ `
  query SearchWaitlistUsers(
    $filter: SearchableWaitlistUserFilterInput
    $sort: [SearchableWaitlistUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableWaitlistUserAggregationInput]
  ) {
    searchWaitlistUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        email
        userCity
        userIP
        userCoordinates
        userInternetProvider
        userZipCode
        userState
        userTimeZone
        relationshipStatus
        cityState
        referralCode
        verificationLinkToken
        position
        ageRange
        gender
        genderPreference
        readyInterests
        howDidYouHearAboutUs
        helpReady
        features
        comments
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;

export const addContactToList = /* GraphQL */ `
  query AddContactToList($email: String!) {
    addContactToList(email: $email)
  }
`;
