import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { CareersPage } from "./pages/CareersPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { TermsOfUsePage } from "./pages/TermsOfUsePage";
import { SignUpFormPage } from "./pages/SignUpFormPage";
import { MyPositionPage } from "./pages/MyPositionPage";

export const AppRoutes = () => {
  return (
    <div className="App h-screen w-full flex items-center justify-center bg-vanilla p-6 text-charcoal antialiased">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/not-found" element={<NotFoundPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-use" element={<TermsOfUsePage />} />
          <Route path="/sign-up-form" element={<SignUpFormPage />} />
          <Route path="/my-position" element={<MyPositionPage />} />
        </Routes>
      </Router>
    </div>
  );
};
