import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export const PrivacyPolicyPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.sessionStorage.removeItem("rd-email");
    const getIsSiteEnabled =
      window.sessionStorage.getItem("isReadySiteEnabled");
    const readyCurrentTime = window.sessionStorage.getItem("readyCurrentTime");
    const readyTimeDiff1 = dayjs();
    const readyTimeDiff2 = dayjs(readyCurrentTime);
    const minutes = readyTimeDiff1.diff(readyTimeDiff2, "minutes");
    if (
      !window.location.hostname.includes("dev.") &&
      !window.location.hostname.includes("localhost")
    ) {
      return;
    } else {
      if (getIsSiteEnabled === "true" && minutes < 30) {
        return;
      } else {
        window.sessionStorage.removeItem("readyCurrentTime");
        window.sessionStorage.removeItem("isReadySiteEnabled");
        window.location = "https://dev.readyplatform.co/";
      }
    }
  }, []);

  return (
    <main className="relative h-full w-full flex flex-col items-center justify-start text-left bg-vanilla">
      <section>
        <img
          className="mt-16 sm:mt-0"
          width={100}
          src={require("./../assets/images/ready-logo-charcoal.svg").default}
          alt="Ready Platform Logo"
        />
        <h1 className="w-full text-3xl mt-12 mb-6 font-bold font-ff">
          Ready Platform Inc. Privacy Policy
        </h1>
        <p className="c8 mb-4">
          <span className="c1">Effective: April 12, 2024</span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.{" "}
          </span>
        </p>
        <h2 className="c11 mb-4 font-bold" id="h.30j0zll">
          <span className="c10 c2">Interpretation and Definitions</span>
        </h2>
        <h3 className="c4 mb-4 font-bold" id="h.1fob9te">
          <span className="c10 c2">Interpretation</span>
        </h3>
        <p className="c8">
          <span className="c1">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </span>
        </p>
        <h3 className="c4 mb-4" id="h.3znysh7">
          <span className="c10 c2">Definitions</span>
        </h3>
        <p className="c8">
          <span className="c1">For the purposes of this Privacy Policy:</span>
        </p>
        <ul className="c14 lst-kix_list_2-0 star list-disc ml-12 mb-4">
          <li className="c5 li-bullet-0">
            <span className="c2">Account</span>
            <span className="c1">
              &nbsp;means a unique account created for You to access our Service
              or parts of our Service.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Affiliate</span>
            <span className="c1">
              &nbsp;means an entity that controls, is controlled by or is under
              common control with a party, where &quot;control&quot; means
              ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other
              managing authority.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Company</span>
            <span className="c1">
              &nbsp;(referred to as either &quot;the Company&quot;,
              &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
              Agreement) refers to Ready Platform, Inc., 3031 Tisch Way, 110
              Plaza West, San Jose, CA 95128.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Cookies</span>
            <span className="c1">
              &nbsp;are small files that are placed on Your computer, mobile
              device or any other device by a website, containing the details of
              Your browsing history on that website among its many uses.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Country</span>
            <span className="c1">
              &nbsp;refers to: California, United States
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Device</span>
            <span className="c1">
              &nbsp;means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Personal Data</span>
            <span className="c1">
              &nbsp;means any information from or about a person that either
              identifies that person directly or that makes that person
              identifiable when it is combined with other information from or
              about that person from any source.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Service</span>
            <span className="c1">
              &nbsp;refers to the Website, software, mobile applications,
              content and all other products and services.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Service Provider</span>
            <span className="c1">
              &nbsp;means any natural or legal person who processes the data on
              behalf of the Company. It refers to third-party companies or
              individuals employed by the Company to facilitate the Service, to
              provide the Service on behalf of the Company, to perform services
              related to the Service or to assist the Company in analyzing how
              the Service is used.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Third-party Social Media Service</span>
            <span className="c1">
              &nbsp;refers to any website or any social network website through
              which a User can log in or create an account to use the Service.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Usage Data</span>
            <span className="c1">
              &nbsp;refers to data collected automatically, either generated by
              the use of the Service or from the Service infrastructure itself
              (for example, the duration of a page visit).
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">Website</span>
            <span className="c6">
              &nbsp;refers to Ready Platform&rsquo;s website, accessible from
            </span>
            <span className="c6 c21">
              <a href="https://www.readyplatform.co/">
                https://www.readyplatform.co/
              </a>
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2">You</span>
            <span className="c1">
              &nbsp;means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </span>
          </li>
        </ul>
        <h2 className="c11 mb-4 font-bold" id="h.2et92p0">
          <span className="c10 c2">
            Collecting and Using Your Personal Data
          </span>
        </h2>
        <h3 className="c4 mb-4 font-bold" id="h.tyjcwt">
          <span className="c10 c2">Types of Data Collected</span>
        </h3>
        <h4 className="c12 mb-4 font-bold" id="h.3dy6vkm">
          <span className="c10 c2">Personal Data</span>
        </h4>
        <p className="c8 mb-4">
          <span className="c1">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </span>
        </p>
        <ol className="c14 lst-kix_list_7-0 start list-decimal ml-12 mb-4">
          <li className="c7 li-bullet-0">
            <span className="c1">
              Name (We do not describe it as full name)
            </span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Email</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Bio (About me)</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Birthdate</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Ethnicity</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Gender</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Gender preference for dating</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Location (City / State)</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Photo</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Height</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Personal Values</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Usage Data</span>
          </li>
          <li className="c7 li-bullet-0">
            <span className="c1">Instagram handle</span>
          </li>
          <li className="c22 li-bullet-0">
            <span className="c1">
              Any other information You decide to provide
            </span>
          </li>
        </ol>
        <p className="c8 c15">
          <span className="c1"></span>
        </p>
        <h4 className="c12 mb-4 font-bold" id="h.1t3h5sf">
          <span className="c10 c2">Usage Data</span>
        </h4>
        <p className="c8 mb-4">
          <span className="c1">
            Usage Data is collected automatically when using the Service.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            Usage Data may include information such as Your Device&#39;s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that You visit, the time and date
            of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </span>
        </p>
        <h3 className="c4 mb-4 font-bold" id="h.2s8eyo1">
          <span className="c2 c10">Use of Your Personal Data</span>
        </h3>
        <p className="c8 mb-4">
          <span className="c1">
            The Company may use Personal Data for the following purposes:
          </span>
        </p>
        <ul className="c14 lst-kix_list_1-0 start list-disc ml-12 mb-4">
          <li className="c5 li-bullet-0 font-bold">
            <span className="c2">To match you with another User.</span>
          </li>
          <li className="c5 li-bullet-0 font-bold">
            <span className="c2">To provide and maintain our Service</span>
            <span className="c1">
              , including to monitor the usage of our Service.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2 font-bold">To manage Your Account:</span>
            <span className="c1">
              &nbsp;to manage Your registration as a user of the Service. The
              Personal Data You provide can give You access to different
              functionalities of the Service that are available to You as a
              registered user.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2  font-bold">To contact You:</span>
            <span className="c1">
              &nbsp;To contact You by email, SMS, or other equivalent forms of
              electronic communication, such as a mobile application&#39;s push
              notifications regarding updates or informative communications
              related to the functionalities, products or contracted services,
              including the security updates, when necessary or reasonable for
              their implementation.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2 font-bold">To manage Your requests:</span>
            <span className="c1">
              &nbsp;To attend and manage Your requests to Us.
            </span>
          </li>
          <li className="c5 li-bullet-0">
            <span className="c2 font-bold">For business transfers:</span>
            <span className="c1">
              &nbsp;We may use Your information to evaluate or conduct a merger,{" "}
            </span>
          </li>
          <li className="c8 c24 li-bullet-0">
            <span className="c2 font-bold">For other purposes</span>
            <span className="c1">
              : We may use Your information for other purposes, such as data
              analysis, identifying usage trends, determining the effectiveness
              of our promotional campaigns and to evaluate and improve our
              Service, products, services, marketing and your experience.
            </span>
          </li>
        </ul>
        <p className="c8 mb-4">
          <span className="c1">
            We may share Your Personal Data in the following situations:
          </span>
        </p>
        <ul className="ml-12 list-disc mb-4">
          <li className="c19 c17">
            <span className="c2">With Service Providers:</span>
            <span className="c1">
              &nbsp;We may share Your Personal Data with Service Providers to
              monitor and analyze the use of our Service, to contact You.
            </span>
          </li>
          <li className="c17 c19">
            <span className="c2">With Affiliates:</span>
            <span className="c1">
              &nbsp;We may share Your information with Our affiliates, in which
              case we will require those affiliates to honor this Privacy
              Policy. Affiliates include Our parent company and any other
              subsidiaries, joint venture partners or other companies that We
              control or that are under common control with Us.
            </span>
          </li>
          <li className="c19 c17">
            <span className="c2">With other users:</span>
            <span className="c1">
              &nbsp;when You share Personal Data or otherwise interact in the
              public areas with other users, such information may be viewed by
              all users and may be publicly distributed outside. If You interact
              with other users or register through a Third-Party Social Media
              Service, Your contacts on the Third-Party Social Media Service may
              see Your name, profile, pictures and description of Your activity.
              Similarly, other users will be able to view descriptions of Your
              activity, communicate with You and view Your profile.
            </span>
          </li>
          <li className="c8 c17">
            <span className="c2">With Your consent</span>
            <span className="c1">
              : We may disclose Your Personal Data for any other purpose with
              Your consent.
            </span>
          </li>
        </ul>
        <h3 className="c4 mb-4 font-bold" id="h.17dp8vu">
          <span className="c10 c2">Retention of Your Personal Data</span>
        </h3>
        <p className="c8 mb-4">
          <span className="c1">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </span>
        </p>
        <h3 className="c4 mb-4 font-bold" id="h.3rdcrjn">
          <span className="c10 c2">Transfer of Your Personal Data</span>
        </h3>
        <p className="c8 mb-4">
          <span className="c1">
            Your information, including Personal Data, is processed at the
            Company&#39;s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to &mdash; and maintained on &mdash;
            computers located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other Personal Data.
          </span>
        </p>
        <h3 className="c4 mb-4 font-bold" id="h.26in1rg">
          <span className="c10 c2">Delete Your Personal Data</span>
        </h3>
        <p className="c8 mb-4">
          <span className="c1">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your Personal
            Data. You may also contact Us to request access to, correct, or
            delete any Personal Data that You have provided to Us.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </span>
        </p>
        <h3 className="c4 mb-4 font-bold" id="h.lnxbz9">
          <span className="c10 c2">Disclosure of Your Personal Data</span>
        </h3>
        <h4 className="c12 mb-4 font-bold" id="h.35nkun2">
          <span className="c10 c2">Law enforcement</span>
        </h4>
        <p className="c8 mb-4">
          <span className="c1">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </span>
        </p>
        <h4 className="c12 mb-4 font-bold" id="h.1ksv4uv">
          <span className="c10 c2">Other legal requirements</span>
        </h4>
        <p className="c8 mb-4">
          <span className="c1">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </span>
        </p>
        <ul className="mb-4 ml-12 list-disc">
          <li className="c19 c17">
            <span className="c1">Comply with a legal obligation</span>
          </li>
          <li className="c19 c17">
            <span className="c1">
              Protect and defend the rights or property of the Company
            </span>
          </li>
          <li className="c19 c17">
            <span className="c1">
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </span>
          </li>
          <li className="c19 c17">
            <span className="c1">
              Protect the personal safety of Users of the Service or the public
            </span>
          </li>
          <li className="c8 c17">
            <span className="c1">Protect against legal liability</span>
          </li>
        </ul>
        <h3 className="c4 mb-4 font-bold" id="h.44sinio">
          <span className="c10 c2">Security of Your Personal Data</span>
        </h3>
        <p className="c8 mb-4">
          <span className="c1">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </span>
        </p>
        <h2 className="c11 mb-4 font-bold" id="h.2jxsxqh">
          <span className="c10 c2">California Residents</span>
        </h2>
        <p className="c3 mb-4">
          <span className="c1">
            California Civil Code Section 1798.83 requires certain businesses
            that share customer Personal Data with third parties for the third
            parties&rsquo; direct marketing purposes to respond to requests from
            California customers asking about the businesses&rsquo; practices
            related to such information-sharing. We currently do not share or
            disclose your Personal Data to third parties for the third
            parties&rsquo; direct marketing purposes. &nbsp;If we change our
            practices in the future, we will implement an opt-out policy as
            required under California laws.
          </span>
        </p>
        <p className="c3 mb-4">
          <span className="c1">
            Furthermore, subject to certain exemptions, California residents
            have the following rights with respect to Personal Data we may have
            collected about them:{" "}
          </span>
        </p>
        <p className="c3 mb-4 font-bold">
          <span className="c2 c13">Requests to Know</span>
        </p>
        <p className="c3">
          <span className="c1">
            You have the right to request that we disclose:{" "}
          </span>
        </p>
        <ul className="c14 lst-kix_list_3-0 start list-disc mb-4 ml-12">
          <li className="c0 li-bullet-0">
            <span className="c1">
              The categories of Personal Data we have collected about you;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              The categories of Personal Data about you we have sold or
              disclosed for a business purpose;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              The categories of sources from which we have collected Personal
              Data about you;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              The business or commercial purposes for selling or collecting
              Personal Data about you;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              The categories of Personal Data sold or shared, if any, about you,
              as well as the categories of third parties to whom the Personal
              Data was sold, by category of Personal Data for each party to whom
              Personal Data was sold; and
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              The specific pieces of Personal Data collected.
            </span>
          </li>
        </ul>
        <p className="c3 mb-4">
          <span className="c6">You may submit a request to know via</span>
          <span className="c6">
            &nbsp;email to request-to-know@readyplatform.co
          </span>
          <span className="c6">. </span>
          <span className="c1">
            The delivery of our response may take place electronically or by
            mail. We are not required to respond to requests to know more than
            twice in a 12-month period.
          </span>
        </p>
        <p className="c3 mb-4">
          <span className="c1">
            We do not sell, and have not in the prior 12 months sold, Personal
            Data about California residents. Therefore, we have not included a
            &ldquo;Do Not Sell My Personal Info&rdquo; link on our Site. If our
            practices change, we will update this Privacy Policy and take any
            other necessary action to comply with applicable law.{" "}
          </span>
        </p>
        <p className="c3 mb-4 font-bold">
          <span className="c13 c2">Requests to Delete</span>
        </p>
        <p className="c3 mb-4">
          <span className="c1">
            You have the right to request that we delete any Personal Data about
            you that we have collected. Upon receiving a verified request to
            delete Personal Data, we will do so unless otherwise required or
            authorized by law. You may submit a request to delete Personal Data
            via email to delete-account@readyplatform.co.
          </span>
        </p>
        <p className="c3 mb-4 font-bold">
          <span className="c13 c2">Authorized Agents</span>
        </p>
        <p className="c3 mb-4">
          <span className="c1">
            You may designate an authorized agent to make requests on your
            behalf. You must provide an authorized agent written permission to
            submit a request on your behalf, and we may require that you verify
            your identity directly with us. Alternatively, an authorized agent
            that has been provided power of attorney pursuant to Probate Code
            sections 4000-4465 may submit a request on your behalf.
          </span>
        </p>
        <p className="c3 mb-4 font-bold" id="h.z337ya">
          <span className="c13 c2">
            Methods for Submitting Consumer Requests and Our Response to
            Requests
          </span>
        </p>
        <p className="c3">
          <span className="c1">
            You may submit a request for access and requests to delete Personal
            Data about you via:
          </span>
        </p>
        <ul className="c14 lst-kix_list_5-0 start mb-4">
          <li className="c0 li-bullet-0">
            <span className="c1 c9">
              via email at delete-account@readyplatform.co
            </span>
          </li>
        </ul>
        <p className="c3 mb-4">
          <span className="c1">
            Upon receipt of a request, we may ask you for additional information
            to verify your identity. Any additional information you provide will
            be used only to verify your identity and not for any other purpose.
          </span>
        </p>
        <p className="c3 mb-4">
          <span className="c6">
            We will acknowledge the receipt of your request as soon as
            reasonably practicable in compliance with applicable laws. Subject
            to our ability to verify your identity, we will respond to your
            request within 45 days of receipt. If we require more time (up to 90
            days), we will inform you of the reason and extension period in
            writing. &nbsp;In order to protect your privacy and the security of
            Personal Data about you
          </span>
          <span className="c6">
            , we verify your request by sending you a confirmation code to your
            email.
          </span>
        </p>
        <p className="c3 mb-4">
          <span className="c1">
            Any disclosures we provide will only cover the 12-month period
            preceding the verifiable consumer request&#39;s receipt. The
            response we provide will also explain the reasons we cannot comply
            with a request, if applicable.
          </span>
        </p>
        <p className="c3 mb-4">
          <span className="c1">
            We do not charge a fee to process or respond to your verifiable
            consumer request unless it is excessive, repetitive, or manifestly
            unfounded. If we determine that the request warrants a fee, we will
            tell you why we made that decision and provide you with a cost
            estimate before completing your request.
          </span>
        </p>
        <p className="c3 mb-4 font-bold">
          <span className="c13 c2">The Right to Non-Discrimination</span>
        </p>
        <p className="c3 mb-4">
          <span className="c1">
            You have the right not to be discriminated against for the exercise
            of your California privacy rights described above. Unless permitted
            by the CCPA, we will not:
          </span>
        </p>
        <ul className="c14 lst-kix_list_5-0 list-disc ml-12 mb-4">
          <li className="c0 li-bullet-0">
            <span className="c1">Deny you goods or services;</span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              Provide you a different level or quality of goods or services; or
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </span>
          </li>
        </ul>
        <h2 className="c11 mb-4 font-bold">
          <span className="c10 c2">Links to Other Websites</span>
        </h2>
        <p className="c8 mb-4">
          <span className="c1">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party&#39;s site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </span>
        </p>
        <h2 className="c11 mb-4 font-bold" id="h.3j2qqm3">
          <span className="c10 c2">Changes to this Privacy Policy</span>
        </h2>
        <p className="c8 mb-4">
          <span className="c1">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </span>
        </p>
        <p className="c8 mb-4">
          <span className="c1">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </span>
        </p>
        <h2 className="c11 mb-4 font-bold" id="h.1y810tw">
          <span className="c10 c2">Contact Us</span>
        </h2>
        <p className="c8 mb-4">
          <span className="c1">
            If you have any questions about this Privacy Policy, You can contact
            us by email:{" "}
            <a href="mailto:waitlist@readyplatform.co">
              waitlist@readyplatform.co
            </a>
          </span>
        </p>
      </section>
      <section className="flex flex-col items-center justify-start w-full z-10 md:pl-12 lg:pl-[100px] text-charcoal mt-10">
        <div className="w-full flex items-center justify-start">
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/terms-of-use")}
          >
            Terms of Service
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/careers")}
          >
            Careers
          </p>
        </div>
        <p className="mr-1 w-full text-left pt-2">
          Questions or concerns? Reach out to us at{" "}
          <a href="mailto:waitlist@readyplatform.co">
            waitlist@readyplatform.co
          </a>
        </p>
      </section>
    </main>
  );
};
