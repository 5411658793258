import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import { IconCopy } from "@tabler/icons-react";

export const MyPositionPage = () => {
  const navigate = useNavigate();

  const [searchparams] = useSearchParams();

  const [userPosition, setUserPosition] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [isUniqueURLCopied, setIsUniqueURLCopied] = useState(false);

  useEffect(() => {
    try {
      const getUserPosition = async () => {
        const userEmail = await searchparams.get("email");
        const getSignupInfo = await axios.post(
          "https://referlist.co/external/getsignupinfo",
          {
            email: userEmail,
          },
          {
            headers: {
              "api-key": process.env.REACT_APP_REFERLIST_API_KEY,
            },
          }
        );
        const { referralCode } = getSignupInfo.data;
        setReferralCode(referralCode);

        const getPosition = await axios.post(
          "https://referlist.co/external/position",
          {
            email: userEmail,
          },
          {
            headers: {
              "api-key": process.env.REACT_APP_REFERLIST_API_KEY,
            },
          }
        );
        const { position } = getPosition.data;
        setUserPosition(position);
      };

      getUserPosition();
    } catch (error) {
      window.location = "https://www.readyplatform.co";
      throw new Error("Error getting user position from Referlist", error);
    }
  }, [searchparams]);

  const copyUniqueURL = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://www.readyplatform.co/?ref=${referralCode}`
      );
      setIsUniqueURLCopied(true);
      setTimeout(() => {
        setIsUniqueURLCopied(false);
      }, 1500);
    } catch (error) {
      console.error("Failed to copy unique URL", error);
      throw new Error("Failed to copy unique URL", error);
    }
  };

  return (
    <main className="relative h-full w-full flex flex-col items-center justify-start text-left bg-vanilla">
      <section className="w-full">
        <img
          className="mt-16 sm:mt-0"
          width={100}
          src={require("./../assets/images/ready-logo-charcoal.svg").default}
          alt="Ready Platform Logo"
        />
      </section>
      <section className="w-10/12 flex flex-col items-start justify-start my-12">
        <p className="font-ff text-4xl my-4">
          Thanks for joining the waitlist for Ready.
        </p>
        {userPosition !== 0 && (
          <p className="py-4 text-xl font-bold">
            You're #{userPosition} in line.
          </p>
        )}
        <p className="pb-4 text-2xl">Want to get priority access?</p>
        <p className="">
          Invite the best people you know using your unique link below, and
          share using the social icons. Your referrals will also get on the
          waitlist, and we’ll bump you higher in line for everyone that joins.
        </p>
        <div className="flex items-center justify-start">
          <p className="py-4">Here's your unique referral link: </p>
        </div>
        <div className="flex items-center justify-start">
          {referralCode !== "" && (
            <p className="mx-2 font-bold">
              https://www.readyplatform.co/?ref=
              {referralCode}
            </p>
          )}
          <IconCopy
            className="cursor-pointer"
            onClick={() => copyUniqueURL()}
            width={24}
            height={24}
          />
          {isUniqueURLCopied && (
            <p className="pl-2 text-xs text-red-500">Unique URL copied!</p>
          )}
        </div>
        <div className="w-full flex flex-row items-center justify-center py-12 h-[50px]">
          <a
            href={`https://api.whatsapp.com/send?text=https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${referralCode}`}
            rel="noreferrer"
            target="_blank"
            className="px-2 h-[42px] w-[42px]"
          >
            <img
              src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-128.png"
              srcSet="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-128.png 1x, https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-256.png 2x"
              alt="Whatsapp Icon"
              width="42"
              height="42"
            />
          </a>
          <a
            href={`https://x.com/intent/tweet?url=https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${referralCode}&hashtags=readyplatform`}
            rel="noreferrer"
            target="_blank"
            className="px-2 h-[42px] w-[42px]"
          >
            <img
              src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-128.png"
              srcSet="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-128.png 1x, https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-256.png 2x"
              alt="X Icon"
              width="42"
              height="42"
            />
          </a>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${referralCode}`}
            rel="noreferrer"
            target="_blank"
            className="px-2 h-[42px] w-[42px]"
          >
            <img
              src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-128.png"
              srcSet="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-128.png 1x, https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-256.png 2x"
              alt="LinkedIn Icon"
              width="42"
              height="42"
            />
          </a>
          <a
            href={`mailto:?body=https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${referralCode}`}
            rel="noreferrer"
            target="_blank"
            className="px-2 h-[42px] w-[42px]"
          >
            <img
              src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/112-gmail_email_mail-64.png"
              srcSet="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/112-gmail_email_mail-128.png 2x"
              alt="Email Icon"
              width="42"
              height="42"
            />
          </a>
        </div>
        <p dir="ltr">
          Thanks for helping us co-create an intentional community of people who
          want to evolve as individuals, and evolve as they date and build a
          relationship.
        </p>
      </section>
      <section className="flex flex-col items-center justify-start w-full z-10 md:pl-12 lg:pl-[100px] text-charcoal mt-10">
        <div className="w-full flex items-center justify-start">
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/terms-of-use")}
          >
            Terms of Service
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/careers")}
          >
            Careers
          </p>
        </div>
        <p className="mr-1 w-full text-left pt-2">
          Questions or concerns? Reach out to us at{" "}
          <a href="mailto:waitlist@readyplatform.co">
            waitlist@readyplatform.co
          </a>
        </p>
      </section>
    </main>
  );
};
