import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export const CareersPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.sessionStorage.removeItem("rd-email");
    const getIsSiteEnabled =
      window.sessionStorage.getItem("isReadySiteEnabled");
    const readyCurrentTime = window.sessionStorage.getItem("readyCurrentTime");
    const readyTimeDiff1 = dayjs();
    const readyTimeDiff2 = dayjs(readyCurrentTime);
    const minutes = readyTimeDiff1.diff(readyTimeDiff2, "minutes");
    if (
      !window.location.hostname.includes("dev.") &&
      !window.location.hostname.includes("localhost")
    ) {
      return;
    } else {
      if (getIsSiteEnabled === "true" && minutes < 30) {
        return;
      } else {
        window.sessionStorage.removeItem("readyCurrentTime");
        window.sessionStorage.removeItem("isReadySiteEnabled");
        window.location = "https://dev.readyplatform.co/";
      }
    }
  }, []);

  return (
    <main className="relative h-full w-full flex flex-col items-center justify-start text-left bg-vanilla">
      <section className="w-full">
        <img
          className="mt-16 sm:mt-0"
          width={100}
          src={require("./../assets/images/ready-logo-charcoal.svg").default}
          alt="Ready Platform Logo"
        />
        <h1 className="w-full text-3xl mt-12 mb-6 font-bold">Careers</h1>
        {/* <p className="mb-4 text-lg">
          Thank you for visiting. Stay tuned for future jobs at Ready!
        </p> */}
        <div className="max-w-[1000px]">
          <p className="w-full text-2xl mt-12 mb-2 font-bold font-inter">
            Social Media and Community Manager (Contract - Part Time)
          </p>
          <p className="w-full text-xl mt-6 mb-2 font-bold font-inter">
            About Ready
          </p>
          <p className="w-full mb-6">
            Ready is an evolved dating app and a home for relationships,
            designed for growth-minded individuals actively seeking serious
            relationships and for couples already in committed partnerships.
            It's a transformative space for nurturing connections. We are a lean
            and agile team building Ready from the ground up, with compassionate
            innovation as our core principle and approach. We are driven by the
            thoughtful use of technology to support human flourishing and are
            committed to revolutionizing the dating and relationship world.
          </p>
          <p className="w-full mb-6">
            Ready's early stages will focus on dating, and will quickly expand
            to support those already in relationships as well as individuals
            looking to learn about healthy relationships and work on themselves.
            By providing prompts, activities, community engagement, and
            expert-driven content, we aim to guide users in creating and
            nurturing meaningful relationships.
          </p>
          <p className="w-full text-xl mt-6 mb-6 font-bold">Responsibilities</p>
          <ul className="list-disc pl-6">
            <li>
              Lead and manage the social media strategy, content creation, and
              publication for all Ready social channels.
            </li>
            <li>
              Ensure timely and accurate execution of social media platforms in
              collaboration with internal team members.
            </li>
            <li>
              Communicate core brand messaging and support overall marketing
              efforts including campaigns, product launches, and brand events
            </li>
            <li>
              Monitor and manage our social community through timely responses
              to customer/member messages on socials and email.
            </li>
            <li>
              Maintain Ready’s brand experience, tone of voice, and design
              guidelines across all social media platforms.
            </li>
            <li>
              Grow social media audiences and increase engagement across all
              channels.
            </li>
            <li>
              Cultivate our member community with effective strategies for
              growth and enhanced engagement.
            </li>
            <li>
              Host and support virtual social and community education events,
              covering key events in real-time.
            </li>
            <li>
              Facilitate our testimonial program and member story curation
              process.
            </li>
            <li>
              Serve as the main point of contact for the social media team,
              staying current on all available social opportunities and new
              technologies.
            </li>
            <li>
              Monitor, analyze, and report on social listening and community
              insights, capturing online reviews, customer feedback, and
              sentiment.
            </li>
          </ul>
          <p className="w-full text-xl mt-12 mb-6 font-bold">
            Skills and Qualifications
          </p>
          <ul className="list-disc pl-6">
            <li>
              2+years of experience in digital and social media marketing,
              content creation, copywriting, and community management.
            </li>
            <li>
              Keen interest in personal growth and helping people build
              authentic relationships is a big plus.{" "}
            </li>
            <li>
              Strong knowledge of key social media platforms and best practices,
              including Facebook, Twitter, Instagram, TikTok, YouTube, and new
              platforms.
            </li>
            <li>
              Experience building and scaling social-first marketing programs.
            </li>
            <li>
              Proven track record in designing, launching, and maintaining
              community programs using social media to build and strengthen
              customer relationships.
            </li>
            <li>Experience hosting and participating in virtual events.</li>
            <li>
              Familiarity with social listening platforms and data-driven social
              listening tools.
            </li>
            <li>
              Detail-oriented and results-driven with excellent project
              management skills.
            </li>
            <li>
              Creative thinking with a growth mindset, ready to adapt and
              innovate with new tools, concepts, platforms, and processes.
            </li>
            <li>
              Strong interpersonal communication skills (verbal, written).
            </li>
          </ul>
          <p className="mt-6 mb-16">
            If you’re interested,{" "}
            <a
              className="underline font-bold"
              rel="noreferrer"
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdjhFJlw6BZlCMiTYtfxWd7N42NLVnI3Zyiu0ayFrS75WfVLQ/viewform?usp=sf_link"
            >
              please fill out this form and upload your resume.
            </a>{" "}
            We look forward to hearing from you.
          </p>
        </div>
      </section>
      <section className="flex flex-col items-center justify-start w-full z-10 md:pl-12 lg:pl-[100px] text-charcoal mt-10">
        <div className="w-full flex items-center justify-start">
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/terms-of-use")}
          >
            Terms of Service
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/careers")}
          >
            Careers
          </p>
        </div>
        <p className="mr-1 w-full text-left pt-2">
          Questions or concerns? Reach out to us at{" "}
          <a href="mailto:waitlist@readyplatform.co">
            waitlist@readyplatform.co
          </a>
        </p>
      </section>
    </main>
  );
};
