import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

// import { IconCopy } from "@tabler/icons-react";

// Amplify
import { generateClient } from "aws-amplify/api";
import {
  createWaitlistUser,
  updateWaitlistUser,
} from "../graphql/customMutations";

// GraphQL Client
const client = generateClient({ authMode: "apiKey" });

export const SignUpFormPage = () => {
  const navigate = useNavigate();

  const [searchparams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isThankYouPage, setIsThankYouPage] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isAgeValid, setIsAgeValid] = useState(true);
  const [isGenderValid, setIsGenderValid] = useState(true);
  const [isPreferencesValid, setIsPreferencesValid] = useState(true);
  const [isRelationshipStatusValid, setIsRelationshipStatusValid] =
    useState(true);
  const [isDistanceValid, setIsDistanceValid] = useState(true);
  const [isReadyInterestsValid, setIsReadyInterestsValid] = useState(true);
  const [isHowDidYouHearAboutUsValid, setIsHowDidYouHearAboutUsValid] =
    useState(true);
  const [isHelpReadyValid, setIsHelpReadyValid] = useState(true);
  const [isDeviceValid, setIsDeviceValid] = useState(true);
  const [errorState, setErrorState] = useState(false);
  // const [referralCode, setReferralCode] = useState("");
  // const [isUniqueURLCopied, setIsUniqueURLCopied] = useState(false);

  useEffect(() => {
    window.sessionStorage.removeItem("rd-email");
    const getIsSiteEnabled =
      window.sessionStorage.getItem("isReadySiteEnabled");
    const readyCurrentTime = window.sessionStorage.getItem("readyCurrentTime");
    const readyTimeDiff1 = dayjs();
    const readyTimeDiff2 = dayjs(readyCurrentTime);
    const minutes = readyTimeDiff1.diff(readyTimeDiff2, "minutes");
    if (
      !window.location.hostname.includes("dev.") &&
      !window.location.hostname.includes("localhost")
    ) {
      return;
    } else {
      if (getIsSiteEnabled === "true" && minutes < 30) {
        return;
      } else {
        window.sessionStorage.removeItem("readyCurrentTime");
        window.sessionStorage.removeItem("isReadySiteEnabled");
        window.location = "https://dev.readyplatform.co/";
      }
    }
  }, []);

  const submitSubscriptionForm = async () => {
    try {
      // Reset Error state
      setErrorState(false);
      // Set loading state
      setIsLoading(true);
      // Reset variables
      setIsEmailValid(true);
      setIsAgeValid(true);
      setIsGenderValid(true);
      setIsPreferencesValid(true);
      setIsRelationshipStatusValid(true);
      setIsDistanceValid(true);
      setIsReadyInterestsValid(true);
      setIsHowDidYouHearAboutUsValid(true);
      setIsHelpReadyValid(true);
      setIsDeviceValid(true);

      const userId = searchparams.get("userId");

      const userEmail = document.getElementsByName("email")[0].value;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isEmailValidFormat = emailPattern.test(userEmail);

      if (!userEmail) setIsEmailValid(false);
      if (!isEmailValidFormat) setIsEmailValid(false);

      const getAgeRange = document.getElementsByName("whats_ur_age_range");
      const ageRange = Array.from(getAgeRange).find((radio) => radio.checked);

      if (!ageRange) setIsAgeValid(false);

      const getGender = document.getElementsByName(
        "how_do_u_prefer_to_identify",
      );
      const gender = Array.from(getGender).find((radio) => radio.checked);

      if (!gender) setIsGenderValid(false);

      let preferences = [];
      const selectedPreferences = document.getElementsByName(
        "who_are_u_open_to_dating",
      );
      for (let i = 0; selectedPreferences[i]; ++i) {
        if (selectedPreferences[i].checked) {
          preferences.push(selectedPreferences[i].value);
        }
      }

      if (!preferences.length) setIsPreferencesValid(false);

      const getRelationshipStatus = document.getElementsByName(
        "whats_ur_current_relationship_status",
      );
      const relationshipStatus = Array.from(getRelationshipStatus).find(
        (radio) => radio.checked,
      );

      if (!relationshipStatus) setIsRelationshipStatusValid(false);

      const getDistanceStatus = document.getElementsByName(
        "how_important_is_distance",
      );
      const distanceStatus = Array.from(getDistanceStatus).find(
        (radio) => radio.checked,
      );
      if (!distanceStatus) setIsDistanceValid(false);

      const getDeviceStatus = document.getElementsByName("device_type");
      const deviceStatus = Array.from(getDeviceStatus).find(
        (radio) => radio.checked,
      );
      if (!deviceStatus) setIsDeviceValid(false);

      let readyInterests = [];
      const selectedInterests = document.getElementsByName(
        "what_aspect_of_ready_interests_you_the_most",
      );
      for (let i = 0; selectedInterests[i]; ++i) {
        if (selectedInterests[i].checked) {
          readyInterests.push(selectedInterests[i].value);
        }
      }

      if (!readyInterests.length) setIsReadyInterestsValid(false);

      const getHowDidYouHearAboutUs = document.getElementsByName(
        "how_did_u_hear_about_us",
      );
      const howDidYouHearAboutUs = Array.from(getHowDidYouHearAboutUs).find(
        (radio) => radio.checked,
      );

      if (!howDidYouHearAboutUs) setIsHowDidYouHearAboutUsValid(false);

      const getHelpReady = document.getElementsByName(
        "interested_in_helping_build_ready",
      );
      const helpReady = Array.from(getHelpReady).find((radio) => radio.checked);

      if (!helpReady) setIsHelpReadyValid(false);

      setTimeout(async () => {
        console.log(
          "Error",
          !isEmailValidFormat,
          !userEmail,
          !ageRange?.value,
          !gender?.value,
          preferences.length === 0,
          !relationshipStatus?.value,
          !distanceStatus?.value,
          readyInterests.length === 0,
          !howDidYouHearAboutUs?.value,
          !helpReady?.value,
          !isDeviceValid.value,
        );

        if (
          !isEmailValidFormat ||
          !userEmail ||
          !ageRange?.value ||
          !gender?.value ||
          preferences.length === 0 ||
          !relationshipStatus?.value ||
          !distanceStatus?.value ||
          readyInterests.length === 0 ||
          !howDidYouHearAboutUs?.value ||
          !helpReady?.value ||
          !deviceStatus?.value
        ) {
          console.log("Fields incomplete!");
          setIsLoading(false);
          setErrorState(true);
          return;
        }

        if (userId) {
          console.log("Existing User");
          const existingUserValues = {
            id: userId,
            ageRange: ageRange.value,
            gender: gender.value,
            genderPreference: preferences.join(", "),
            relationshipStatus: relationshipStatus.value,
            isDistanceImportant: distanceStatus.value,
            deviceType: deviceStatus.value,
            readyInterests: readyInterests.join(", "),
            howDidYouHearAboutUs: howDidYouHearAboutUs.value,
            helpReady: helpReady.value,
            features: document.getElementById(
              "whats_one_feature_youd_love_to_see_in_ready",
            ).value,
            comments: document.getElementById("anything_else").value,
          };

          await client.graphql({
            query: updateWaitlistUser,
            variables: {
              input: existingUserValues,
            },
          });
        } else {
          // get user referral code
          // const response = await axios.post(
          //   "https://referlist.co/external/getsignupinfo",
          //   {
          //     email: searchparams.get("email"),
          //   },
          //   {
          //     headers: {
          //       "api-key": process.env.REACT_APP_REFERLIST_API_KEY,
          //     },
          //   }
          // );

          // setReferralCode(response.data.referralCode);

          // Get user location data
          const locationbyIP = await axios.get("https://ipinfo.io");
          const { city, ip, loc, org, postal, region, timezone } =
            locationbyIP.data;
          const userValues = {
            email: document.getElementsByName("email")[0].value,
            // referralCode: searchparams.get("referralCode"),
            userCity: city,
            userIP: ip,
            userCoordinates: loc,
            userInternetProvider: org,
            userZipCode: postal,
            userState: region,
            userTimeZone: timezone,
            ageRange: ageRange.value,
            gender: gender.value,
            genderPreference: preferences.join(", "),
            relationshipStatus: relationshipStatus.value,
            isDistanceImportant: distanceStatus.value,
            deviceType: deviceStatus.value,
            readyInterests: readyInterests.join(", "),
            howDidYouHearAboutUs: howDidYouHearAboutUs.value,
            helpReady: helpReady.value,
            features: document.getElementById(
              "whats_one_feature_youd_love_to_see_in_ready",
            ).value,
            comments: document.getElementById("anything_else").value,
          };
          await client.graphql({
            query: createWaitlistUser,
            variables: {
              input: userValues,
            },
          });
        }

        setIsLoading(false);
        setIsThankYouPage(true);
      }, 1000);
    } catch (error) {
      console.log("Error submitting form", error);
      setIsLoading(false);
      setErrorState(true);
    }
  };

  // const copyUniqueURL = async () => {
  //   try {
  //     let getReferralCode = "";
  //     if (searchparams.get("referralCode")) {
  //       getReferralCode = searchparams.get("referralCode");
  //     }
  //     if (referralCode !== "") {
  //       getReferralCode = referralCode;
  //     }
  //     await navigator.clipboard.writeText(
  //       `https://www.readyplatform.co/?ref=${getReferralCode}`
  //     );
  //     setIsUniqueURLCopied(true);
  //     setTimeout(() => {
  //       setIsUniqueURLCopied(false);
  //     }, 1500);
  //   } catch (err) {
  //     console.error("Failed to copy: ", err);
  //   }
  // };

  return (
    <main className="relative h-full w-full flex flex-col items-center justify-start text-left bg-vanilla">
      <section className="w-full">
        <img
          className="mt-16 sm:mt-0"
          width={100}
          src={require("./../assets/images/ready-logo-charcoal.svg").default}
          alt="Ready Platform Logo"
        />
      </section>
      {isThankYouPage ? (
        <section className="w-10/12 flex flex-col items-start justify-start my-12">
          <p className="font-ff text-4xl my-4">
            Thanks for joining the waitlist for Ready.
          </p>
          {/* {searchparams.get("position") && (
            <p className="py-4 text-xl font-bold">
              You're #{searchparams.get("position")} in line.
            </p>
          )} */}
          {/* <p className="pb-4 text-2xl">Want to get priority access?</p>
          <p className="">
            Invite the best people you know using your unique link below, and
            share using the social icons. Your referrals will also get on the
            waitlist, and we’ll bump you higher in line for everyone that joins.
          </p>
          <div className="flex items-center justify-start">
            <p className="py-4">Here's your unique referral link: </p>
          </div> */}
          {/* <div className="flex items-center justify-start">
            {searchparams.get("referralCode") && (
              <>
                <p className="pr-2 font-bold">
                  https://www.readyplatform.co/?ref=
                  {searchparams.get("referralCode")}
                </p>
                <IconCopy
                  className="cursor-pointer"
                  onClick={() => copyUniqueURL()}
                  width={24}
                  height={24}
                />
              </>
            )}
            {referralCode !== "" && (
              <>
                <p className="pr-2 font-bold">
                  https://www.readyplatform.co/?ref=
                  {referralCode}
                </p>
                <IconCopy
                  className="cursor-pointer"
                  onClick={() => copyUniqueURL()}
                  width={24}
                  height={24}
                />
              </>
            )}
            {isUniqueURLCopied && (
              <p className="pl-2 text-xs text-red-500">Unique URL copied!</p>
            )}
          </div>
          <div className="w-full flex flex-row items-center justify-center py-12 h-[50px]">
            {searchparams.get("referralCode") && (
              <>
                <a
                  href={`https://api.whatsapp.com/send?text=Finding love, and staying in love, can be hard. Ready is here to make it easier. Use my link to get on the waitlist: https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${searchparams.get(
                    "referralCode"
                  )}`}
                  rel="noreferrer"
                  target="_blank"
                  className="px-2 h-[42px] w-[42px]"
                >
                  <img
                    src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-128.png"
                    srcSet="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-128.png 1x, https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-256.png 2x"
                    alt="Whatsapp Icon"
                    width="42"
                    height="42"
                  />
                </a>
                <a
                  href={`https://x.com/intent/tweet?url=https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${searchparams.get(
                    "referralCode"
                  )}&hashtags=readyplatform`}
                  rel="noreferrer"
                  target="_blank"
                  className="px-2 h-[42px] w-[42px]"
                >
                  <img
                    src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-128.png"
                    srcSet="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-128.png 1x, https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-256.png 2x"
                    alt="X Icon"
                    width="42"
                    height="42"
                  />
                </a>
                <a
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${searchparams.get(
                    "referralCode"
                  )}`}
                  rel="noreferrer"
                  target="_blank"
                  className="px-2 h-[42px] w-[42px]"
                >
                  <img
                    src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-128.png"
                    srcSet="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-128.png 1x, https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-256.png 2x"
                    alt="LinkedIn Icon"
                    width="42"
                    height="42"
                  />
                </a>
                <a
                  href={`mailto:?subject=Dating is broken. Ready is here to help.&body=Finding love, and staying in love, can be hard. Ready is here to make it easier. Use my link to get on the waitlist: https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${searchparams.get(
                    "referralCode"
                  )}`}
                  rel="noreferrer"
                  target="_blank"
                  className="px-2 h-[42px] w-[42px]"
                >
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/112-gmail_email_mail-64.png"
                    srcSet="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/112-gmail_email_mail-128.png 2x"
                    alt="Email Icon"
                    width="42"
                    height="42"
                  />
                </a>
              </>
            )}
            {referralCode !== "" && (
              <>
                <a
                  href={`https://api.whatsapp.com/send?text=Finding love, and staying in love, can be hard. Ready is here to make it easier. Use my link to get on the waitlist: https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${referralCode}`}
                  rel="noreferrer"
                  target="_blank"
                  className="px-2 h-[42px] w-[42px]"
                >
                  <img
                    src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-128.png"
                    srcSet="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-128.png 1x, https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-256.png 2x"
                    alt="Whatsapp Icon"
                    width="42"
                    height="42"
                  />
                </a>
                <a
                  href={`https://x.com/intent/tweet?url=https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${referralCode}&hashtags=readyplatform`}
                  rel="noreferrer"
                  target="_blank"
                  className="px-2 h-[42px] w-[42px]"
                >
                  <img
                    src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-128.png"
                    srcSet="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-128.png 1x, https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-256.png 2x"
                    alt="X Icon"
                    width="42"
                    height="42"
                  />
                </a>
                <a
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${referralCode}`}
                  rel="noreferrer"
                  target="_blank"
                  className="px-2 h-[42px] w-[42px]"
                >
                  <img
                    src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-128.png"
                    srcSet="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-128.png 1x, https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-256.png 2x"
                    alt="LinkedIn Icon"
                    width="42"
                    height="42"
                  />
                </a>
                <a
                  href={`mailto:?subject=Dating is broken. Ready is here to help.&body=Finding love, and staying in love, can be hard. Ready is here to make it easier. Use my link to get on the waitlist: https%3A%2F%2Fwww.readyplatform.co%2F%3Fref%3D${referralCode}`}
                  rel="noreferrer"
                  target="_blank"
                  className="px-2 h-[42px] w-[42px]"
                >
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/112-gmail_email_mail-64.png"
                    srcSet="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/112-gmail_email_mail-128.png 2x"
                    alt="Email Icon"
                    width="42"
                    height="42"
                  />
                </a>
              </>
            )}
          </div> */}
          <p>
            Thanks for helping us co-create an intentional community of people
            who want to evolve as individuals, and evolve as they date and build
            a relationship.
          </p>
        </section>
      ) : (
        <section className="w-10/12 flex items-center justify-center my-12">
          <form
            className="w-full mb-12"
            id="pfy-signup-form"
            acceptCharset="UTF-8"
            action=""
          >
            <input
              type="hidden"
              name="relationshipStatus"
              id="relationshipStatus"
              defaultValue={searchparams.get("relationshipStatus")}
            />
            <input
              type="hidden"
              name="cityState"
              id="cityState"
              defaultValue={searchparams.get("cityState")}
            />
            <input
              type="hidden"
              name="referralCode"
              id="referralCode"
              defaultValue={searchparams.get("referralCode")}
            />
            <h1 className="font-ff text-4xl my-4">You are on the waitlist!</h1>
            {/* <p className="mb-4">
              Check your inbox to confirm your email and your position in line.
            </p> */}
            <p className="mb-4">
              By taking less than a minute to complete the below survey, you'll
              play a crucial role in shaping the features and design of the app.
              Thanks in advance for your invaluable feedback.
            </p>
            <p className="font-inter font-bold mb-2">
              Email address (*){" "}
              {!isEmailValid && (
                <span className="text-red-500 ml-2">
                  - This field is required (check if your email is valid too!)
                </span>
              )}
            </p>
            <input
              type="email"
              name="email"
              id="email"
              defaultValue={
                searchparams.get("email") ? searchparams.get("email") : ""
              }
              className="form-control p-2 mb-4 w-full border border-solid border-black rounded-md"
              placeholder="Enter your email address"
            />
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  What’s your age range? (*){" "}
                  {!isAgeValid && (
                    <span className="text-red-500 ml-2">
                      - This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_age_range"
                    id="survey_question_multi_118679_0"
                    defaultValue="18-24"
                  />
                  <p className="pl-2">18-24</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_age_range"
                    id="survey_question_multi_118679_1"
                    defaultValue="25-34"
                    className="pr-4"
                  />
                  <p className="pl-2">25-34</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_age_range"
                    id="survey_question_multi_118679_2"
                    defaultValue="35-44"
                    className="pr-4"
                  />
                  <p className="pl-2">35-44</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_age_range"
                    id="survey_question_multi_118679_3"
                    defaultValue="45-54"
                    className="pr-4"
                  />
                  <p className="pl-2">45-54</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_age_range"
                    id="survey_question_multi_118679_4"
                    defaultValue="54-64"
                    className="pr-4"
                  />
                  <p className="pl-2">54-64</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_age_range"
                    id="survey_question_multi_118679_5"
                    defaultValue="64+"
                    className="pr-4"
                  />
                  <p className="pl-2">64+</p>
                </div>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  How do you prefer to identify? (*){" "}
                  {!isGenderValid && (
                    <span className="text-red-500 ml-2">
                      - This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_do_u_prefer_to_identify"
                    id="survey_question_multi_118680_0"
                    defaultValue="Male"
                    className="pr-4"
                  />
                  <p className="pl-2">Male</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_do_u_prefer_to_identify"
                    id="survey_question_multi_118680_1"
                    defaultValue="Female"
                  />
                  <p className="pl-2">Female</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_do_u_prefer_to_identify"
                    id="survey_question_multi_118680_2"
                    defaultValue="Nonbinary/Other"
                  />
                  <p className="pl-2">Nonbinary/Other</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_do_u_prefer_to_identify"
                    id="survey_question_multi_118680_3"
                    defaultValue="Prefer not to say"
                  />
                  <p className="pl-2">Prefer not to say</p>
                </div>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  Who are you open to dating? (*){" "}
                  {!isPreferencesValid && (
                    <span className="text-red-500 ml-2">
                      - This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="who_are_u_open_to_dating"
                    id="survey_question_multi_118684_0"
                    defaultValue="Men"
                  />
                  <p className="pl-2">Men</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="who_are_u_open_to_dating"
                    id="survey_question_multi_118684_1"
                    defaultValue="Women"
                  />
                  <p className="pl-2">Women</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="who_are_u_open_to_dating"
                    id="survey_question_multi_118684_2"
                    defaultValue="Nonbinary people"
                  />
                  <p className="pl-2">Nonbinary people</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="who_are_u_open_to_dating"
                    id="survey_question_multi_118684_3"
                    defaultValue="Everyone"
                  />
                  <p className="pl-2">Everyone</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="who_are_u_open_to_dating"
                    id="survey_question_multi_118684_4"
                    defaultValue="I'm already in a relationship and not looking to date"
                  />
                  <p className="pl-2">
                    I'm already in a relationship and not looking to date
                  </p>
                </div>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  What’s your current relationship status? (*){" "}
                  {!isRelationshipStatusValid && (
                    <span className="text-red-500 ml-2">
                      - This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_current_relationship_status"
                    id="survey_question_multi_118687_0"
                    defaultValue="Single"
                  />
                  <p className="pl-2">Single</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_current_relationship_status"
                    id="survey_question_multi_118687_1"
                    defaultValue="In a relationship"
                  />
                  <p className="pl-2">In a relationship</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_current_relationship_status"
                    id="survey_question_multi_118687_2"
                    defaultValue="It's complicated"
                  />
                  <p className="pl-2">It's complicated</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="whats_ur_current_relationship_status"
                    id="survey_question_multi_118687_3"
                    defaultValue="Prefer not to say"
                  />
                  <p className="pl-2">Prefer not to say</p>
                </div>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  How important is it that people you’re dating live near you
                  (versus long-distance dating)? (*){" "}
                  {!isDistanceValid && (
                    <span className="text-red-500 ml-2">
                      - This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_important_is_distance"
                    id="survey_question_multi_1118687_0"
                    defaultValue="I’m open to connections anywhere"
                  />
                  <p className="pl-2">I’m open to connections anywhere</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_important_is_distance"
                    id="survey_question_multi_1118687_1"
                    defaultValue="I prefer that they live in my area"
                  />
                  <p className="pl-2">I prefer that they live in my area</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_important_is_distance"
                    id="survey_question_multi_1118687_2"
                    defaultValue="I’m already in a relationship and not looking to date"
                  />
                  <p className="pl-2">
                    I’m already in a relationship and not looking to date
                  </p>
                </div>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  What type of mobile device do you have? (*){" "}
                  {!isDeviceValid && (
                    <span className="text-red-500 ml-2">
                      - This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="device_type"
                    defaultValue="iPhone"
                  />
                  <p className="pl-2">iPhone</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="device_type"
                    defaultValue="Android"
                  />
                  <p className="pl-2">Android</p>
                </div>
                <div className="flex items-center justify-start">
                  <input type="radio" name="device_type" defaultValue="Other" />
                  <p className="pl-2">Other</p>
                </div>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  What aspect of Ready interests you the most? (*){" "}
                  {!isReadyInterestsValid && (
                    <span className="text-red-500 ml-2">
                      - This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="what_aspect_of_ready_interests_you_the_most"
                    id="survey_question_multi_118688_0"
                    defaultValue="Finding meaningful connections"
                  />
                  <p className="pl-2">Finding meaningful connections</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="what_aspect_of_ready_interests_you_the_most"
                    id="survey_question_multi_118688_1"
                    defaultValue="Maintaining current relationship"
                  />
                  <p className="pl-2">Maintaining current relationship</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="what_aspect_of_ready_interests_you_the_most"
                    id="survey_question_multi_118688_2"
                    defaultValue="Personal growth and learning resources"
                  />
                  <p className="pl-2">Personal growth and learning resources</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="what_aspect_of_ready_interests_you_the_most"
                    id="survey_question_multi_118688_3"
                    defaultValue="Communication tools"
                  />
                  <p className="pl-2">Communication tools</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="checkbox"
                    name="what_aspect_of_ready_interests_you_the_most"
                    id="survey_question_multi_118688_4"
                    defaultValue="Inclusivity and safe space"
                  />
                  <p className="pl-2">Inclusivity and safe space</p>
                </div>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  How did you hear about us? (*){" "}
                  {!isHowDidYouHearAboutUsValid && (
                    <span className="text-red-500 ml-2">
                      - This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_did_u_hear_about_us"
                    id="survey_question_multi_118689_0"
                    defaultValue="Friend/Family"
                  />
                  <p className="pl-2">Friend/Family</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_did_u_hear_about_us"
                    id="survey_question_multi_118689_1"
                    defaultValue="Social media"
                  />
                  <p className="pl-2">Social media</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_did_u_hear_about_us"
                    id="survey_question_multi_118689_2"
                    defaultValue="Blog/Article"
                  />
                  <p className="pl-2">Blog/Article</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="how_did_u_hear_about_us"
                    id="survey_question_multi_118689_3"
                    defaultValue="Other"
                  />
                  <p className="pl-2">Other</p>
                </div>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  Are you interested in helping build Ready’s relationship
                  growth platform through surveys and/or interviews? (*){" "}
                  {!isHelpReadyValid && (
                    <span className="text-red-500 ml-2">
                      - This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="interested_in_helping_build_ready"
                    defaultValue="I'm in"
                  />
                  <p className="pl-2">I'm in</p>
                </div>
                <div className="flex items-center justify-start">
                  <input
                    type="radio"
                    name="interested_in_helping_build_ready"
                    defaultValue="No thanks"
                  />
                  <p className="pl-2">No thanks</p>
                </div>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  What’s one feature you’d love to see in the Ready app?
                </div>
                <textarea
                  name="whats_one_feature_youd_love_to_see_in_ready"
                  id="whats_one_feature_youd_love_to_see_in_ready"
                  rows="10"
                  placeholder=""
                  className="w-full border border-solid border-black rounded-md p-2"
                ></textarea>
              </div>
            </div>
            <div className="row pfy-form-row">
              <div className="form-group">
                <div className="font-inter font-bold mt-2">
                  Anything else you'd like to tell us?
                </div>
                <textarea
                  name="anything_else"
                  id="anything_else"
                  className="w-full border border-solid border-black rounded-md p-2"
                  rows="10"
                  placeholder=""
                ></textarea>
              </div>
            </div>
            <div className="row pfy-form-submit-row">
              <button
                className={`${
                  isLoading ? "opacity-40" : "opacity-100"
                } bg-black text-white border border-solid border-black rounded-full flex items-center justify-center w-full cursor-pointer mt-4 py-2 px-4`}
                disabled={isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  submitSubscriptionForm();
                }}
              >
                {isLoading ? "Processing..." : "Submit"}
              </button>
              {errorState && (
                <p className="text-red-500 w-full text-center py-2 font-bold">
                  There is an error in the form. Please complete all required
                  fields.
                </p>
              )}
            </div>
          </form>
        </section>
      )}
      <section className="flex flex-col items-center justify-start w-full z-10 md:pl-12 lg:pl-[100px] text-charcoal mt-10">
        <div className="w-full flex items-center justify-start">
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/terms-of-use")}
          >
            Terms of Service
          </p>
          <p
            className="mr-8 underline cursor-pointer text-sm"
            onClick={() => navigate("/careers")}
          >
            Careers
          </p>
        </div>
        <p className="mr-1 w-full text-left pt-2">
          Questions or concerns? Reach out to us at{" "}
          <a href="mailto:waitlist@readyplatform.co">
            waitlist@readyplatform.co
          </a>
        </p>
      </section>
    </main>
  );
};
