export const createWaitlistUser = /* GraphQL */ `
  mutation CreateWaitlistUser(
    $input: CreateWaitlistUserInput!
    $condition: ModelWaitlistUserConditionInput
  ) {
    createWaitlistUser(input: $input, condition: $condition) {
      id
      email
      relationshipStatus
      cityState
      referralCode
      ageRange
      gender
      genderPreference
      readyInterests
      howDidYouHearAboutUs
      helpReady
      features
      comments
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateWaitlistUser = /* GraphQL */ `
  mutation UpdateWaitlistUser(
    $input: UpdateWaitlistUserInput!
    $condition: ModelWaitlistUserConditionInput
  ) {
    updateWaitlistUser(input: $input, condition: $condition) {
      id
      email
      relationshipStatus
      cityState
      referralCode
      ageRange
      gender
      genderPreference
      readyInterests
      howDidYouHearAboutUs
      helpReady
      features
      comments
      createdAt
      updatedAt
      __typename
    }
  }
`;
